import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'

import coverImage from '../images/pianificazione-finanziaria.jpg'
import CTA from '../components/Cta'

import posterInvestire from '../images/poster-investire-occhi-aperti.png'

const Pianificazione = () => (
  <Layout>
    <SEO title="Pianificazione Finanziaria" />
    <Cover
      title="Pianificazione Finanziaria"
      color="green"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Nel breve video che ti invitiamo a vedere puoi scoprire perché sia più
        sicuro scegliere un approccio ragionato e professionale per investire i
        propri risparmi.
      </p>
    </Cover>
    <VideoBox ytID="yOhBbUI-15M" poster={posterInvestire} />
    <CTA />
  </Layout>
)

export default Pianificazione
